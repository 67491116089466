
import { defineComponent, ref } from '@vue/composition-api'
import { mdiEye, mdiEyeOff, mdiEmail, mdiFormTextboxPassword } from '@mdi/js'

import { useAuthActions, useAuthGetters } from '@/store/modules/auth/useAuthModule'

import { ValidationForm } from '@/utils/types/validation'
import { isRequired, isEmail } from '@/utils/validation'

export default defineComponent({
  name: 'LoginView',
  beforeRouteEnter: (from, to, next) => {
    const { isAuthenticated } = useAuthGetters()
    !isAuthenticated.value ? next() : next({ name: 'home' })
  },
  setup(_, { root }) {
    const { login: authModuleLogin } = useAuthActions(root.$store)
    const isLoading = ref(false)

    const email = ref('')
    const password = ref('')

    const showPassword = ref(false)

    const loginForm = ref<null | ValidationForm>(null)
    const wrongCredentials = ref(false)
    const loginError = () => !wrongCredentials.value || (root.$t('login.error.wrongCredentials') as string)

    const login = () => {
      wrongCredentials.value = false
      if (loginForm.value?.validate()) {
        isLoading.value = true
        authModuleLogin({
          username: email.value,
          password: password.value,
        })
          .then(() => {
            root.$router.push({ name: 'home' })
          })
          .catch(() => {
            wrongCredentials.value = true
            loginForm.value?.validate()
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    }

    return {
      icons: { mdiEye, mdiEyeOff, mdiEmail, mdiFormTextboxPassword },
      isRequired,
      email,
      isEmail,
      loginError,
      password,
      showPassword,
      login,
      isLoading,
      loginForm,
    }
  },
})
