var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', {
    staticClass: "ma-3",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-card', {
    staticClass: "rounded-lg elevation-0",
    attrs: {
      "width": "600"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 primary white--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('login.login')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "loginForm",
    attrs: {
      "id": "login-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.login.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "email",
      "label": _vm.$t('form.field.email'),
      "type": "email",
      "prepend-inner-icon": _vm.icons.mdiEmail,
      "rules": [_vm.isRequired(_vm.email, _vm.$t('form.field.email')), _vm.isEmail(_vm.email, _vm.$t('form.field.email')), _vm.loginError],
      "outlined": ""
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "password",
      "label": _vm.$t('form.field.password'),
      "prepend-inner-icon": _vm.icons.mdiFormTextboxPassword,
      "type": _vm.showPassword ? 'text' : 'password',
      "append-icon": _vm.showPassword ? _vm.icons.mdiEye : _vm.icons.mdiEyeOff,
      "rules": [_vm.isRequired(_vm.password, _vm.$t('form.field.password')), _vm.loginError],
      "outlined": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "small": "",
      "text": "",
      "to": {
        name: 'resetPassword',
        params: {
          email: _vm.email
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.resetPassword')) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "data-cy": "login-btn",
      "color": "primary",
      "type": "submit",
      "form": "login-form",
      "loading": _vm.isLoading
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('login.login')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }